import React from "react";
import { LanguageContext } from "../Contexts";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SettingsWindow.css";
import LanguageString from "./LanguageString";
import { ModalWrapper } from "./ModalWindow";

type LanguageNamesType = {
  [key in LanguageType]: string;
};

const LanguageNames: LanguageNamesType = {
  ko: "한국어",
};

type SettingsWindowProps = {
  handleDismiss: () => void;
  children?: React.ReactNode;
};

type LanguageSelectProps = {
  setLanguage: React.Dispatch<React.SetStateAction<LanguageType | null>>;
};

export default function SettingsWindow({ children, handleDismiss }: SettingsWindowProps) {
  return (
    <ModalWrapper handleDismiss={handleDismiss} className="Settings-Window">
      <div className="Settings-Wrapper">
        <div className="Settings-Title">
          <LanguageString stringId="UI_TEXT_SETTINGS_MODAL_TITLE" />
        </div>
        <div className="Settings-Content">{children}</div>
      </div>
    </ModalWrapper>
  );
}

export function LanguageSelectSetting({ setLanguage }: LanguageSelectProps) {
  const [langDropdownVisible, setLangDropdownVisible] = React.useState(false);
  const language = React.useContext(LanguageContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (!menuRef.current?.contains(event.target as Node)) setLangDropdownVisible(false);
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  });

  const languageList = Object.entries(LanguageNames).map(([key, value], idx) => {
    return (
      <li key={idx + 1} onClick={() => setLanguage(key as LanguageType)}>
        {value}
      </li>
    );
  });

  return (
    <div className="Settings-Option">
      <p className="Settings-Option-Title">
        <LanguageString stringId="UI_TEXT_SETTINGS_LANG_SELECT_TITLE" />
      </p>
      <div className="Language-Selector-Container">
        <div className="Language-Selector-Button" onClick={() => setLangDropdownVisible(!langDropdownVisible)} ref={menuRef}>
          <span>{LanguageNames[language]}</span>
          <FontAwesomeIcon className="chevron" color="#CCCCCC" size="1x" icon={langDropdownVisible ? faChevronUp : faChevronDown} />
        </div>
        {langDropdownVisible && <ul className="Language-Selector-List">{languageList}</ul>}
      </div>
    </div>
  );
}

export function LimitDownloadSpeedSetting() {
  function onChangeDownloadLimit(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = Number(event.target.value);
    const minValue = Number(event.target.min);
    const maxValue = Number(event.target.max);

    if (newValue < minValue) event.target.value = minValue.toString();
    if (newValue > maxValue) event.target.value = maxValue.toString();

    if (newValue !== 0) {
      window.localStorage.setItem("downloadSpeedLimit", event.target.value);
    } else {
      window.localStorage.removeItem("downloadSpeedLimit");
    }

    window.ipcRenderer.send("setDownloadSpeedLimit", event.target.value);
  }

  const defaultDownloadSpeed = window.localStorage.getItem("downloadSpeedLimit") ?? 0;

  return (
    <div className="Settings-Option">
      <p className="Settings-Option-Title">
        <LanguageString stringId="UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE" />
      </p>
      <input type="number" className="Limit-Download-Input" min={0} max={65535} defaultValue={defaultDownloadSpeed} onChange={onChangeDownloadLimit} />
      &nbsp;<span>KB/s (0 = 무제한)</span>
      {/* &nbsp;<span>KB/s (0 = Unlimited)</span> */}
    </div>
  );
}

export function LauncherVersionSetting() {
  return (
    <div className="Settings-Option">
      <p className="Settings-Option-Title">
        <LanguageString stringId="UI_TEXT_SETTINGS_LAUNCHERV_TITLE" />
      </p>
      <p>
        <LanguageString stringId="UI_TEXT_SETTINGS_LAUNCHERV_VERSION" version={launcherVersion} />
      </p>
      {/* <button type="button" className="Launcher-Version-Button"><LanguageString stringId="UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON"/></button>
            <button type="button" className="Launcher-Version-Button"><LanguageString stringId="UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON"/></button> */}
    </div>
  );
}

export function AboutSetting() {
  function onTOSClick() {
    window.ipcRenderer.send("openExternal", "https://mtdream.net/tos");
  }

  function onPrivacyClick() {
    window.ipcRenderer.send("openExternal", "https://mtdream.net/privacy");
  }

  function onServiceStatusClick() {
    window.ipcRenderer.send("openExternal", "https://mtdream.statuspage.io");
  }

  return (
    <div className="Settings-Option">
      <p className="Settings-Option-Title">
        <LanguageString stringId="UI_TEXT_SETTINGS_ABOUT_TITLE" />
      </p>
      <p>Copyright &copy; 2024 DAWN TERA. All rights reserved.</p>
      {/* <p>Copyright &copy; 2023 Menma Systems Single Member P.C. All rights reserved.</p>
      <p>
        <span className="Settings-Anchor-Link" onClick={onTOSClick}>
          <LanguageString stringId="UI_TEXT_SETTINGS_ABOUT_TOS" />
        </span>
        <br />
        <span className="Settings-Anchor-Link" onClick={onPrivacyClick}>
          <LanguageString stringId="UI_TEXT_SETTINGS_ABOUT_PRIVACY" />
        </span>
        <br />
        <span className="Settings-Anchor-Link" onClick={onServiceStatusClick}>
          <LanguageString stringId="UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS" />
        </span>
      </p> */}
    </div>
  );
}
