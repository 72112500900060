import React from "react";
import "./LoginWindow.css";
import LanguageString from "./LanguageString";
import { ModalDismissForm, ModalWrapper } from "./ModalWindow";
import LoadingIcon from "./LoadingIcon";
import GameLogo from "../Resources/logo.png";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

type LoginWindowProps = {
  setLoggedIn: React.Dispatch<React.SetStateAction<string | null>>;
  handleDismiss: () => void;
  onError: (err: string | ErrorObject) => void;
  openRegisterWindow: () => void; // 추가된 prop
};

export default function LoginWindow({ setLoggedIn, handleDismiss, onError, openRegisterWindow }: LoginWindowProps) {
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [turnstileToken, setTurnstileToken] = React.useState<string | null>(null);
  const abortController = React.useRef(new AbortController());
  const usernameInput = React.useRef<HTMLInputElement>(null);
  const passwordInput = React.useRef<HTMLInputElement>(null);
  const turnstileInput = React.useRef<TurnstileInstance>(null);
  const submitButton = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    usernameInput.current?.focus();
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>): Promise<boolean> {
    event.preventDefault();
    if (loggingIn) return false;

    const elements = event.currentTarget.elements;
    const username = (elements.namedItem("username") as HTMLInputElement).value;
    const password = (elements.namedItem("password") as HTMLInputElement).value;
    const stayLoggedIn = (elements.namedItem("stayloggedin") as HTMLInputElement).checked;
    const hwid = window.hardware;

    if (!username || !password) return false;

    setLoggingIn(true);

    try {
      // const response = await fetch("https://launcher.dawntera.com/api/account/login", {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/account/login`, {
        method: "POST",
        body: JSON.stringify({ username, password, stayLoggedIn, turnstileToken, hwid }),
        credentials: "include",
        signal: abortController.current.signal,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (!data.success) {
        switch (data.resultCode) {
          case 400: {
            onError(data.resultMessage);
            break;
          }
          case 404: {
            onError("UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD");
            break;
          }
          case 403: {
            onError("UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED");
            break;
          }
          default: {
            onError("UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR");
          }
        }
        return false;
      }

      if (stayLoggedIn) {
        window.localStorage.setItem("username", data.username);
      }
      setLoggedIn(data.username);
      window.ipcRenderer.send("launchGame");

      return true;
    } catch (err) {
      if (!(err instanceof DOMException) || err.name !== "AbortError") {
        onError("UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT");
        return false;
      }
      return true;
    } finally {
      setLoggingIn(false);

      if (passwordInput.current) passwordInput.current.value = "";
      if (submitButton.current) submitButton.current.disabled = true;
      if (turnstileInput.current) turnstileInput.current.reset();
    }
  }

  function onInputChange() {
    if (!usernameInput.current || !passwordInput.current || !submitButton.current) return;
    submitButton.current.disabled = !usernameInput.current.value || !passwordInput.current.value;
  }

  function abortLogin() {
    abortController.current.abort();
    setLoggingIn(false);
    handleDismiss();
  }

  return (
    <ModalWrapper handleDismiss={loggingIn ? abortLogin : handleDismiss} className="Login-Window">
      <div className="Login-Wrapper">
        <div className="Login-Title">
          <img src={GameLogo} alt="Logo" />
        </div>
        <div className="Login-Content">
          <ModalDismissForm onSubmit={handleSubmit}>
            <input type="text" name="username" className="Form-Input" placeholder="아이디" disabled={loggingIn} ref={usernameInput} onChange={onInputChange} />
            <input type="password" name="password" className="Form-Input" placeholder="비밀번호" disabled={loggingIn} ref={passwordInput} onChange={onInputChange} />
            {/* <input type="text" name="username" className="Form-Input" placeholder="Username" disabled={loggingIn} ref={usernameInput} onChange={onInputChange} />
            <input type="password" name="password" className="Form-Input" placeholder="Password" disabled={loggingIn} ref={passwordInput} onChange={onInputChange} /> */}
            <div>
              <input type="checkbox" id="stayloggedin" name="stayloggedin" style={{ marginLeft: "0" }} disabled={loggingIn} />
              <label htmlFor="stayloggedin">
                <LanguageString stringId="UI_TEXT_LOGIN_MODAL_REMEMBER_ME" />
              </label>
            </div>
            <Turnstile siteKey="0x4AAAAAAAh3FjBGaIfrzklC" onSuccess={setTurnstileToken} onExpire={() => setTurnstileToken(null)} onError={console.error} ref={turnstileInput} options={{ size: "invisible" }} />
            <button type="submit" className="Form-Submit" ref={submitButton} disabled>
              <LanguageString stringId="UI_TEXT_LOGIN_MODAL_LOG_IN" />
            </button>
          </ModalDismissForm>
          <div
            className="Form-Links"
            //  style={{ pointerEvents: "none", opacity: 0.5, color: "gray" }}
          >
            <div onClick={openRegisterWindow}>
              <LanguageString stringId="UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT" />
            </div>
            {/* <div onClick={() => window.ipcRenderer.send("openExternal", "https://account.mtdream.net/#/passwordreset")}>
              <LanguageString stringId="UI_TEXT_LOGIN_MODAL_PASSWORD_RESET" />
            </div> */}
          </div>
        </div>
      </div>
      {loggingIn && <LoadingIcon dim />}
    </ModalWrapper>
  );
}
