import React from "react";
import { LanguageContext } from "../Contexts";

type LanguageSubStrings = {
  [key: string]: string;
};

type LanguageStrings = {
  [key: string]: string | LanguageSubStrings;
};

type LanguageStringsWrapper = {
  [key in LanguageType]: LanguageStrings;
};

const strings: LanguageStringsWrapper = {
  ko: {
    UI_TEXT_UPDATE_CHECK_FOR_UPDATES: "업데이트 확인 중...",
    UI_TEXT_UPDATE_DOWNLOADING_UPDATE: "업데이트 다운로드 중 {percentage}%...",
    UI_TEXT_UPDATE_VERIFYING_INTEGRITY: "무결성 확인 중...",
    UI_TEXT_UPDATE_PREPARING_TO_INSTALL: "업데이트 설치 준비 중...",
    UI_TEXT_UPDATE_INSTALLING_UPDATE: "업데이트 설치 중...",
    UI_TEXT_UPDATE_FAILED: "업데이트 실패 오류: {error}",
    UI_TEXT_WELCOME_USERNAME: "Welcome, {username}",
    UI_TEXT_LOG_OUT: "로그아웃",
    UI_TEXT_PATCH_PROGRESS_COMPLETED: "완료",
    UI_TEXT_PATCH_PROGRESS_CHECK_FOR_UPDATES: "업데이트 확인 중...",
    UI_TEXT_PATCH_PROGRESS_RETRIEVING_INFO: "업데이트 정보 검색 중...",
    UI_TEXT_PATCH_PROGRESS_PREALLOCATING: "저장 공간 할당 중...",
    UI_TEXT_PATCH_PROGRESS_CHECKING_EXISTING: "기존 파일을 확인하는 중입니다. 잠시만 기다려 주세요. 시간이 좀 걸릴 수 있습니다...",
    UI_TEXT_PATCH_PROGRESS_DOWNLOADING_FILES: "다운로드 파일 {percentage}%... ({downloadSize}/{totalSize} - {downloadSpeed} - 남은 시간: {timeRemaining})",
    UI_TEXT_PATCH_PROGRESS_EXTRACTING_FILES: "파일 추출 중 {percentage}%...",
    UI_TEXT_PATCH_PROGRESS_CLEANING_UP: "청소 중...",
    UI_TEXT_PATCH_PROGRESS_DOWNLOAD_PAUSED: "다운로드 정지 {percentage}%. ({downloadSize}/{totalSize})",
    UI_TEXT_PATCH_PROGRESS_FAILED: "업데이트 실패, 오류가 발생했습니다. 런처를 다시 시작하고 시도해 주세요.",
    UI_TEXT_PATCH_PROGRESS_REMOVE_LEGACY_INSTALL: "기존 파일을 제거하는 중입니다. 잠시 기다려 주십시오...",
    UI_TEXT_LAUNCH_BUTTON_LOG_IN: "로그인",
    UI_TEXT_LAUNCH_BUTTON_PLAY: "게임 시작",
    UI_TEXT_LAUNCH_BUTTON_LAUNCHING: "시작 중...",
    UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING: "실행 중",
    UI_TEXT_PATCH_PROGRESS_BUTTON_PAUSE: "정지",
    UI_TEXT_PATCH_PROGRESS_BUTTON_RESUME: "재시작",
    UI_TEXT_LOGIN_MODAL_REMEMBER_ME: "로그인 상태 유지",
    UI_TEXT_LOGIN_MODAL_PASSWORD_RESET: "비밀번호 찾기",
    UI_TEXT_LOGIN_MODAL_CREATE_NEW_ACCOUNT: "회원가입",
    UI_TEXT_LOGIN_FAIL_INVALID_USERNAME_PASSWORD: "잘못된 사용자 아이디 또는 비밀번호.",
    UI_TEXT_LOGIN_FAIL_ACCOUNT_NOT_ACTIVATED: "계정이 비활성화 상태입니다, 활성화 링크는 이메일을 확인하십시오.",
    UI_TEXT_LOGIN_FAIL_UNEXPECTED_ERROR: "예기치 않은 오류입니다. 다시 시도하십시오.",
    UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT: "서버에 연결할 수 없습니다. 다시 시도하십시오.",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES: "게임 업데이트 확인",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME: "게임 복구",
    UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER: "게임 폴더 열기",
    UI_TEXT_LOGIN_MODAL_LOG_IN: "로그인",
    UI_TEXT_REGISTER_MODAL_LOG_IN: "회원가입",
    UI_TEXT_NOTICE_MODAL_TITLE: "공지사항",
    UI_TEXT_CLIENT_REPAIR_MODAL_TITLE: "클라이언트 복구",
    UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE: "복구를 진행할 수 있습니다. 시간이 오래 걸릴 수 있습니다. 계속하시겠습니까?",
    UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START: "복구 시작",
    UI_TEXT_GENERIC_MODAL_BUTTON_OK: "확인",
    UI_TEXT_GENERIC_MODAL_BUTTON_CANCEL: "취소",
    UI_TEXT_TOS_MODAL_TITLE: "서비스 약관 및 개인정보 취급방침",
    UI_TEXT_TOS_MODAL_DESCRIPTION: "계속하기 전에 아래 링크의 서비스 약관 및 개인 정보 보호 정책을 읽어보십시오. 동의를 클릭하면 해당 조항을 이해하고 동의했음을 나타냅니다.",
    UI_TEXT_TOS_MODAL_CLICK_BELOW_MESSAGE: "서비스 약관 및 개인 정보 보호 정책을 보려면 아래를 클릭하십시오.",
    UI_TEXT_TOS_MODAL_TOS_CHECKBOX: "서비스 조건",
    UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX: "개인정보 보호정책",
    UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT: "동의",
    UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE: "거절",
    UI_TEXT_CONNECTION_ERROR: "서버에 연결할 수 없습니다. 다시 시도하십시오.",
    UI_TEXT_UNEXPECTED_SERVER_RESPONSE: "서버스 응답 상태 ({errorCode})",
    UI_TEXT_SETTINGS_MODAL_TITLE: "설정",
    UI_TEXT_SETTINGS_LANG_SELECT_TITLE: "언어",
    UI_TEXT_SETTINGS_LIMIT_DOWNLOAD_TITLE: "다운로드 속도 제한",
    UI_TEXT_SETTINGS_LAUNCHERV_TITLE: "런처 버전",
    UI_TEXT_SETTINGS_LAUNCHERV_VERSION: "버전 {version}",
    UI_TEXT_SETTINGS_ABOUT_TITLE: "정보",
    UI_TEXT_SETTINGS_LAUNCHERV_UPDATE_BUTTON: "런처 업데이트",
    UI_TEXT_SETTINGS_LAUNCHERV_REPAIR_BUTTON: "런처 복구",
    UI_TEXT_SETTINGS_ABOUT_TOS: "서비스 조건",
    UI_TEXT_SETTINGS_ABOUT_PRIVACY: "개인정보 보호정책",
    UI_TEXT_SETTINGS_ABOUT_SERVICE_STATUS: "서비스 상태",
    UI_TEXT_TOOLTIP_ACCOUNT: "계정",
    UI_TEXT_TOOLTIP_PROXY: "툴박스/프록시",
    UI_TEXT_TOOLTIP_SETTINGS: "설정",
    UI_TEXT_TOOLTIP_MINIMIZE: "최소화",
    UI_TEXT_TOOLTIP_CLOSE: "닫기",
    UI_TEXT_BANMODAL_TITLE: "Account Banned",
    UI_TEXT_BANMODAL_MESSAGE: "Your account has been banned due to a violation of our Terms of Service and/or game rules.\n\nReason: {banReason}\nEnds At: {banExpirationDate}",
    UI_TEXT_BANMODAL_READ_RULES: "Please take some time to review the game rules below. They are always available in our discord server.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE: "Please type <strong>'I have read and understood the game rules'</strong> to continue. You will not be able to play until you understand the rules, even if the ban has already expired.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE: "I have read and understood the game rules",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE: "Please take some time to review the game rules in our discord server and wait for the ban to expire.",
    UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON: "I Understand",
    UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS: {
      "1": "Violation of Game Rule #1 (Disallowed Skill Prediction Module)",
      "2": "Violation of Game Rule #2 (Botting)",
      "3": "Violation of Game Rule #3 (Non-Macro Skill Automation)",
      "4": "Violation of Game Rule #4 (Cheating Animation Speed / Attack Speed)",
      "5": "Violation of Game Rule #5 (Skill Replacer)",
      "6": "Violation of Game Rule #6 (Invincibility Cheating)",
      "7": "Violation of Game Rule #7 (Cheating Projectiles)",
      "8": "Violation of Game Rule #8 (Inhuman Actions)",
      "9": "Violation of Game Rule #9 (Bug, Exploit or Glitch Abuse)",
      "10": "Violation of Game Rule #10 (Teleportation Abuse)",
      "11": "Violation of Game Rule #11 (Holding Gameplay Hostage)",
      "12": "Violation of Game Rule #12 (Kick Abuse)",
      "13": "Violation of Game Rule #13 (Automating Cleric)",
      "14": "Violation of Game Rule #14 (PvP Interference)",
      "101": "Management Deliberation, Contact Support",
      "102": "Multiple Game Rule Violations, Contact Support",
      "0": "Other Terms of Service Violations, Contact Support",
    },
    UI_TEXT_TERMINATION_ERROR_MESSAGES: {
      "5": "종료 코드 5: DirectX를 찾을 수 없거나 손상되었거나 잘못된 버전이 설치되었습니다.",
      "6": "종료 코드 6: 데이터 센터에 잘못된 데이터가 있습니다.",
      "8": "종료 코드 8: 네트워크 오류가 발생했습니다.",
      "9": "종료 코드 9: 클라이언트 인증에 실패하였습니다. 다시 시도해 주세요.",
      "10": "종료 코드 10: 메모리가 부족합니다.",
      "11": "종료 코드 11: DirectX 초기화에 실패했습니다.",
      "12": "종료 코드 12: 사용 중인 그래픽 카드가 지원하지 않거나 문제가 발생했습니다.",
      "13": "종료 코드 13: 정시간동안 동작이 없어 게임이 종료되었습니다.",
      "16": "종료 코드 16: 서버에 의해 연결이 종료되었습니다.",
      "33": "종료 코드 33: 서버에 의해 연결이 종료되었습니다.",
      "34": "종료 코드 34: 서버에 의해 연결이 종료되었습니다.",
      "257": "종료 코드 257: 로그인 인증 키가 일치하지 않습니다.",
      "259": "종료 코드 259: 이미 접속된 계정입니다.",
      "265": "종료 코드 265: 클라이언트가 손상되었습니다.",
      "275": "종료 코드 275: 클라이언트를 실행할 수 없습니다.",
      "-1": "클라이언트가 비정상적으로 종료되었습니다.",
      unknown: "클라이언트가 종료되었습니다.",
    },
    UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES: {
      "401": "자동 로그인 인증이 만료되었습니다. 다시 로그인해주세요.",
      "503": "현재 서버 점검이 진행 중입니다.",
      "1000": "게임을 시작할 수 없습니다. Win32 오류 코드 {win32ErrorCode}.",
      "4031": "귀하의 계정은 서비스 약관 위반으로 인해 {banExpirationDate}일까지 게임 접속이 제한되었습니다. 자세한 내용은 게임 지원팀으로 문의하시기 바랍니다.",
      "4032": "의심스러운 행동. VPN 서비스를 비활성화하고 다시 시도하십시오. 자세한 내용은 게임 지원팀에 문의하십시오.",
      unknown: "서버 리스트를 불러올 수 없습니다.\n로그인 다시 해주세요.",
    },
  },
};

export default function LanguageString(props: any) {
  const language = React.useContext(LanguageContext);

  const { stringId, ...params }: { stringId: string; params: any } = props;
  let str = strings[language][stringId] ?? stringId;

  if (typeof str === "string") {
    for (const [key, value] of Object.entries(params)) {
      str = str.replaceAll(`{${key}}`, value);
    }

    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  }

  return null;
}

export function BanMessage({ stringId, banReason, banExpirationDate }: { stringId?: string; banReason: number; banExpirationDate: number }) {
  const language = React.useContext(LanguageContext);

  let str = strings[language][stringId ?? "UI_TEXT_BANMODAL_MESSAGE"] as string;
  const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, { year: "numeric", month: "long", weekday: "long", day: "numeric" });
  const banExpirationDateStr = dateTimeFormat.format(new Date(banExpirationDate));
  const banReasonStr = (strings[language]["UI_TEXT_BANMODAL_MESSAGE_BAN_REASONS"] as LanguageSubStrings)[banReason];
  str = str
    .replaceAll("{banExpirationDate}", banExpirationDateStr)
    .replaceAll("{banReason}", banReasonStr ?? "N/A, Contact Support")
    .replaceAll("\n", "<br>");
  return <p dangerouslySetInnerHTML={{ __html: str }}></p>;
}

export function TerminationErrorMessage({ info }: { info: GameExit }) {
  const language = React.useContext(LanguageContext);

  const msgCategory = info.clientTerminated ? "UI_TEXT_TERMINATION_ERROR_MESSAGES" : "UI_TEXT_CLIENT_LAUNCH_ERROR_MESSAGES";

  let str = (strings[language][msgCategory] as LanguageSubStrings)[info.code] ?? (strings[language][msgCategory] as LanguageSubStrings)["unknown"];

  for (const [key, value] of Object.entries(info)) {
    if (key === "banExpirationDate" && typeof value === "number") {
      str = str.replaceAll("{banExpirationDate}", new Date(value).toLocaleString());
    } else {
      str = str.replaceAll(`{${key}}`, value.toString());
    }
  }

  return <>{str}</>;
}

export function getString(language: LanguageType, stringId: string): string {
  return (strings[language][stringId] ?? stringId) as string;
}
