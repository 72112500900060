import React from "react";
import "./GameRules.css";
import { LanguageContext } from "../Contexts";

export default function GameRules() {
  const language = React.useContext(LanguageContext);

  const ko = (
    <ol start={0} className="Game-Rules-Container">
      <li>
        The only officially supported game mods are the ones that are already pre-installed in our built-in &quot;Toolbox&quot;, as well as mods listed in the &quot;Get More Mods&quot; tab in toolbox.
        <ul>
          <li>Any other mod is considered unsupported. Cheats, scripts, hacks and/or software that provide an advantage over your co-players are strictly prohibited. MT: The Dream team reserves every right to restrict your account from accessing the game indefinitely, if they determine usage of any kind of unsupported mods.</li>
        </ul>
      </li>
      <li>
        We only officially support the use of our NGSP skill prediction (available in our toolbox). Any other skill prediction modules are unsupported, and you are liable for ensuring that it follows our rules.
        <ul>
          <li>If we determine that fair play is disrupted, we reserve the right to take action against usage of other, unsupported skill predictions.</li>
        </ul>
      </li>
      <li>
        Any software/mod that attempts to fully automate (&quot;bot&quot;) some (or all) parts of our game is not allowed.
        <ul>
          <li>Example: Auto Fish</li>
          <li>Note: Usage of &quot;AutoHotkey&quot; and &quot;Macro Maker&quot; is allowed unless a macro break one of our other rules.</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that automate skill casts or any part of the lock-on mechanic are not allowed.
        <ul>
          <li>Examples: Auto Arise, Auto Heal, Auto Target</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that speed up skill animations or reduce animation locks beyond normal limits are not allowed.
        <ul>
          <li>Examples: Any &quot;fast&quot; mod, Instant Retaliate, Instant Revive</li>
        </ul>
      </li>
      <li>
        Any software/mod or macro(s) that ignore a skill&apos;s chain or cast requirements are not allowed.
        <ul>
          <li>Example: Skill Replacer</li>
        </ul>
      </li>
      <li>
        Any software/mod that make you immune to damage and/or crowd control effects are not allowed.
        <ul>
          <li>Examples: Anti CC, any &quot;invincibility&quot; mod</li>
        </ul>
      </li>
      <li>Any software/mod that automatically hit your projectiles for you are not allowed.</li>
      <li>
        Any software/mod or macro(s) that perform otherwise impossible actions are not allowed.
        <ul>
          <li>Example: Barrage Skip</li>
        </ul>
      </li>
      <li>Do not abuse bugs, exploits or glitches, including those from Skill Prediction.</li>
      <li>Do not abuse teleport software/mod.</li>
      <li>
        Do not hold people&apos;s gameplay hostage by not participating.
        <ul>
          <li>If you willingly enter content such as a dungeon or battleground, you must participate or leave the group via /drop.</li>
        </ul>
      </li>
      <li>
        Do not abuse the kick feature.
        <ul>
          <li>Kicks are only to be used if someone is AFK or intentionally trolling. Do not kick for any other reason, even if someone is &quot;bad&quot; or flaming.</li>
        </ul>
      </li>
    </ol>
  );

  switch (language) {
    case "ko":
      return ko;
    default:
      return ko;
  }
}
