import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import LanguageString, { BanMessage, TerminationErrorMessage, getString } from "./LanguageString";
import LoadingIcon from "./LoadingIcon";
import "./ModalWindow.css";
import { LanguageContext, ModalDismissContext } from "../Contexts";
import GameRules from "./GameRules";

type ModalWrapperProps = {
  handleDismiss: () => void;
  scale?: number;
  className?: string;
  children?: ReactNode;
};

type TermsWindowProps = {
  handleDismiss: () => void;
  onError: (err: string | ErrorObject) => void;
};

type TerminationWindowProps = {
  info: GameExit;
  handleDismiss: () => void;
};

type ModalWindowProps = {
  title: string;
  body: string;
  buttonText?: string;
  onConfirm: () => void;
  handleDismiss: () => void;
};

export function ModalWrapper({ children, className, scale = 1, handleDismiss }: ModalWrapperProps) {
  const backdropRef = React.useRef<HTMLDivElement>(null);
  const windowRef = React.useRef<HTMLDivElement>(null);
  const transitionDuration = 200;
  const backdropOpacity = "0.7";

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (backdropRef.current) backdropRef.current.style.opacity = backdropOpacity;
      if (windowRef.current) {
        windowRef.current.style.opacity = "1";
        windowRef.current.style.scale = scale.toString();
      }
    }, 10);

    function onKeydown(event: KeyboardEvent) {
      if (event.key === "Escape") dismiss();
    }

    window.addEventListener("keydown", onKeydown);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("keydown", onKeydown);
    };
  }, []);

  function dismiss() {
    if (backdropRef.current) backdropRef.current.style.opacity = "0";
    if (windowRef.current) {
      windowRef.current.style.opacity = "0";
      windowRef.current.style.scale = (scale - 0.3).toString();
    }

    setTimeout(handleDismiss, transitionDuration);
  }

  return (
    <div className="Modal-Wrapper">
      <div className="Modal-Backdrop" ref={backdropRef} style={{ transitionDuration: `${transitionDuration.toString()}ms` }} onClick={dismiss} />
      <div className={"Modal-Window" + (className ? " " + className : "")} style={{ transitionDuration: `${transitionDuration.toString()}ms`, scale: (scale - 0.3).toString() }} ref={windowRef}>
        <div className="Modal-Dismiss-Button" onClick={dismiss}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <ModalDismissContext.Provider value={dismiss}>{children}</ModalDismissContext.Provider>
      </div>
    </div>
  );
}

interface ModalDismissButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => Promise<boolean>;
}

export function ModalDismissButton({ onClick, children, ...props }: ModalDismissButtonProps) {
  const dismiss = React.useContext(ModalDismissContext);
  if (!dismiss) throw new Error("ModalDismissButton must be a child of ModalWrapper");

  function handleClick() {
    if (onClick) {
      onClick().then((shouldDismiss) => {
        if (dismiss && shouldDismiss) dismiss();
      });
    } else if (dismiss) dismiss();
  }

  return (
    <button {...props} onClick={handleClick}>
      {children}
    </button>
  );
}

interface ModalDismissFormProps extends React.ButtonHTMLAttributes<HTMLFormElement> {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
}

export function ModalDismissForm({ onSubmit, children, ...props }: ModalDismissFormProps) {
  const dismiss = React.useContext(ModalDismissContext);
  if (!dismiss) throw new Error("ModalDismissForm must be a child of ModalWrapper");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (onSubmit) {
      onSubmit(event).then((shouldDismiss) => {
        if (dismiss && shouldDismiss) dismiss();
      });
    } else if (dismiss) dismiss();
  }

  return (
    <form {...props} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

export function TermsOfServiceWindow({ handleDismiss, onError }: TermsWindowProps) {
  const [loading, setLoading] = React.useState(false);
  const abortController = React.useRef(new AbortController());

  async function processConfirm() {
    if (loading) return false;

    setLoading(true);

    try {
      // const response = await fetch("https://launcher.dawntera.com/api/account/accept_tos", {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/account/accept_tos`, {
        method: "PATCH",
        credentials: "include",
        signal: abortController.current.signal,
      });
      const data = await response.json();

      if (!data.success) {
        onError({ messageId: "UI_TEXT_UNEXPECTED_SERVER_RESPONSE", errorCode: data.resultCode });
        return false;
      }

      window.ipcRenderer.send("launchGame");
      return true;
    } catch (err) {
      if (err instanceof DOMException && err.name === "AbortError") {
        return true;
      } else {
        onError("UI_TEXT_CONNECTION_ERROR");
        return false;
      }
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <ModalWrapper handleDismiss={() => abortController.current.abort()} className="Generic-Modal Tos-Modal">
        <LoadingIcon />
      </ModalWrapper>
    );
  }

  const termsOfServiceUrl = "https://mtdream.net/tos";
  const privacyPolicyUrl = "https://mtdream.net/privacy";

  return (
    <ModalWrapper handleDismiss={handleDismiss} className="Generic-Modal Tos-Modal">
      <div className="Generic-Modal-Wrapper Tos-Modal-Wrapper">
        <div className="Generic-Modal-Title">
          <LanguageString stringId="UI_TEXT_TOS_MODAL_TITLE" />
        </div>
        <div className="Generic-Modal-Content Tos-Modal-Content">
          <p>
            <LanguageString stringId="UI_TEXT_TOS_MODAL_DESCRIPTION" />
          </p>
          <ul className="Tos-Modal-Checklist">
            <li onClick={() => window.ipcRenderer.send("openExternal", termsOfServiceUrl)}>
              <LanguageString stringId="UI_TEXT_TOS_MODAL_TOS_CHECKBOX" />
            </li>
            <li onClick={() => window.ipcRenderer.send("openExternal", privacyPolicyUrl)}>
              <LanguageString stringId="UI_TEXT_TOS_MODAL_PRIVACY_CHECKBOX" />
            </li>
          </ul>
        </div>
        <div className="Generic-Modal-Buttons">
          <ModalDismissButton type="button" className="Generic-Modal-Button Dismiss-Button">
            <LanguageString stringId="UI_TEXT_GENERIC_MODAL_BUTTON_DECLINE" />
          </ModalDismissButton>
          <ModalDismissButton type="button" className="Generic-Modal-Button Confirm-Button" onClick={processConfirm}>
            <LanguageString stringId="UI_TEXT_GENERIC_MODAL_BUTTON_ACCEPT" />
          </ModalDismissButton>
        </div>
      </div>
    </ModalWrapper>
  );
}

export function TerminationWindow({ info, handleDismiss }: TerminationWindowProps) {
  return (
    <ModalWrapper handleDismiss={handleDismiss} className="Generic-Modal">
      <div className="Generic-Modal-Wrapper">
        <div className="Generic-Modal-Title">
          <LanguageString stringId="UI_TEXT_NOTICE_MODAL_TITLE" />
        </div>
        <div className="Generic-Modal-Content">
          <TerminationErrorMessage info={info} />
        </div>
        <ModalDismissButton type="button" className="Generic-Modal-Button Confirm-Button">
          <LanguageString stringId="UI_TEXT_GENERIC_MODAL_BUTTON_OK" />
        </ModalDismissButton>
      </div>
    </ModalWrapper>
  );
}

export function ModalWindow({ title, body, buttonText = "UI_TEXT_GENERIC_MODAL_BUTTON_OK", onConfirm, handleDismiss }: ModalWindowProps) {
  function onClick() {
    if (onConfirm) onConfirm();
    return Promise.resolve(true);
  }

  return (
    <ModalWrapper handleDismiss={handleDismiss} className="Generic-Modal">
      <div className="Generic-Modal-Wrapper">
        <div className="Generic-Modal-Title">
          <LanguageString stringId={title} />
        </div>
        <div className="Generic-Modal-Content">
          <LanguageString stringId={body} />
        </div>
        <ModalDismissButton type="button" className="Generic-Modal-Button Confirm-Button" onClick={onClick}>
          <LanguageString stringId={buttonText} />
        </ModalDismissButton>
      </div>
    </ModalWrapper>
  );
}

export function BannedWindow({ info, handleDismiss, onError }: { info: BanInfo; handleDismiss: () => void; onError: (err: string | ErrorObject) => void }) {
  const [loading, setLoading] = React.useState(false);
  const [enableAckButton, setEnableAckButton] = React.useState(false);
  const abortController = React.useRef(new AbortController());
  const language = React.useContext(LanguageContext);
  const ackString = getString(language, "UI_TEXT_BANMODAL_ACKNOWLEDGE_REQUIRED_MESSAGE");

  async function onAcknowledge(): Promise<boolean> {
    if (loading) return false;
    setLoading(true);

    try {
      const response = await fetch("https://launcher.mtdream.net/api/account/ack_ban", {
        method: "PATCH",
        credentials: "include",
        signal: abortController.current.signal,
      });
      const data = await response.json();

      if (!data.success) {
        onError({ messageId: "UI_TEXT_UNEXPECTED_SERVER_RESPONSE", errorCode: data.resultCode });
        return false;
      }

      window.ipcRenderer.send("launchGame");
      return true;
    } catch (err) {
      if (err instanceof DOMException && err.name === "AbortError") {
        return true;
      } else {
        onError("UI_TEXT_CONNECTION_ERROR");
        return false;
      }
    } finally {
      setLoading(false);
    }
  }

  function onChangeAckMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setEnableAckButton(e.target.value.toLowerCase() === ackString.toLowerCase());
  }

  function onAbort() {
    abortController.current.abort();
    handleDismiss();
  }

  return (
    <ModalWrapper handleDismiss={loading ? onAbort : handleDismiss} className={"Banned-Modal" + (info.banAck ? " Ban-Ack-Complete" : "")}>
      <div className="Generic-Modal-Wrapper" style={{ maxWidth: "100%" }}>
        <div className="Generic-Modal-Title">
          <LanguageString stringId="UI_TEXT_BANMODAL_TITLE" />
        </div>
        <div className="Generic-Modal-Content" style={{ textAlign: "left", fontSize: "16px" }}>
          <BanMessage banReason={info.banReason} banExpirationDate={info.banExpirationDate} />
          {!info.banAck && (
            <>
              <p>
                <LanguageString stringId="UI_TEXT_BANMODAL_READ_RULES" />
              </p>
              <GameRules />
              <LanguageString stringId="UI_TEXT_BANMODAL_ACKNOWLEDGE_MESSAGE" />
              <textarea rows={1} name="acknowledge" className="Banned-Ack-TextArea" placeholder="Type here..." onChange={onChangeAckMessage} maxLength={ackString.length} />
            </>
          )}
          {info.banAck && (
            <p>
              <LanguageString stringId="UI_TEXT_BANMODAL_ACKNOWLEDGE_COMPLETE" />
            </p>
          )}
        </div>
        {!info.banAck && (
          <ModalDismissButton type="button" className="Generic-Modal-Button Confirm-Button" onClick={onAcknowledge} disabled={!enableAckButton}>
            <LanguageString stringId="UI_TEXT_BANMODAL_ACKNOWLEDGE_BUTTON" />
          </ModalDismissButton>
        )}
        {info.banAck && (
          <ModalDismissButton type="button" className="Generic-Modal-Button Confirm-Button">
            <LanguageString stringId="UI_TEXT_GENERIC_MODAL_BUTTON_OK" />
          </ModalDismissButton>
        )}
      </div>
      {loading && <LoadingIcon dim />}
    </ModalWrapper>
  );
}
