import React from "react";
import "./Update.css";
import LoadingIcon from "./Components/LoadingIcon";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageString from "./Components/LanguageString";
import { LanguageContext } from "./Contexts";
import GameLogo from "./Resources/logo.png";

const enum UpdateStatus {
  Checking,
  Downloading,
  PreparingToInstall,
  Error,
}

interface UpdateInfo {
  status: UpdateStatus;
  progress?: number;
  error?: string;
}

export default function Update() {
  const [updateStatus, setUpdateInfo] = React.useState<UpdateInfo>({ status: UpdateStatus.Checking });
  const [language, setLanguage] = React.useState<LanguageType | null>(null);

  React.useEffect(() => {
    const _language = window.localStorage.getItem("language");
    if (_language) setLanguage(_language as LanguageType);

    window.ipcRenderer.on("updateStatus", (_, info: UpdateInfo) => {
      setUpdateInfo(info);
    });
  }, []);

  function handleDismiss() {
    window.ipcRenderer.send("closeUpdateWindow");
  }

  let updateStatusMsg: JSX.Element;
  let loadingVisible: JSX.Element | null = (
    <div className="App-Updater-Loading-Icon">
      <LoadingIcon />
    </div>
  );

  switch (updateStatus.status) {
    case UpdateStatus.Checking:
      updateStatusMsg = <LanguageString stringId="UI_TEXT_UPDATE_CHECK_FOR_UPDATES" />;
      break;
    case UpdateStatus.Downloading:
      updateStatusMsg = <LanguageString stringId="UI_TEXT_UPDATE_DOWNLOADING_UPDATE" percentage={updateStatus.progress?.toFixed(2)} />;
      break;
    case UpdateStatus.PreparingToInstall:
      updateStatusMsg = <LanguageString stringId="UI_TEXT_UPDATE_PREPARING_TO_INSTALL" />;
      break;
    case UpdateStatus.Error:
      updateStatusMsg = <LanguageString stringId="UI_TEXT_UPDATE_FAILED" error={updateStatus.error} />;
      loadingVisible = null;
      break;
  }

  return (
    <LanguageContext.Provider value={language ?? "ko"}>
      <div className="App-Updater">
        <div className="App-Updater-Drag-Bar">
          <div className="App-Updater-Close-Button" onClick={() => handleDismiss()}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="App-Updater-Content">
          <div className="App-Updater-Logo">
            <img src={GameLogo} alt="DAWN - Launcher" />
          </div>
          {loadingVisible}
          <div className="App-Updater-Status">{updateStatusMsg}</div>
        </div>
      </div>
    </LanguageContext.Provider>
  );
}
